<template>
  <div class="box">
    <wang-edit :txt="content" :callback="setText"></wang-edit>
    <!--ck-edit :txt="content" :callback="setText" :idx="1"></ck-edit>
    <ck-edit :txt="content2" :callback="setText" :idx="2"></ck-edit-->
    <el-button type="primary" @click="submitForm">保存</el-button>
  </div>
</template>
<script>

// import WangEdit from "../components/WangEdit";
import WangEdit from "../components/CkEdit";
export default {
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      content: '1111',
      content2: '22222'
    };
  },
  components: {
    WangEdit
    // CkEdit
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    setText(txt,idx) {
      if(idx==1) {
        this.content = txt
      } else {
        this.content2 = txt
      }
    },
    submitForm() {
      console.log(this.content)
      console.log('brbrbrbrbrbrbrbrbrbrbrbr')
      console.log(this.content2)
    }
  }
};
</script>
<style scoped>
</style>
